/**
 * @param {Window} globalScope
 * @returns {typeof ShopSteps}
 */
export function defShopSteps({ HTMLElement, customElements }) {
  class ShopSteps extends HTMLElement {
    static register(tagName = "shop-steps") {
      this.tagName = tagName;
      customElements.define(tagName, this);
    }

    goto(step) {
      this.dispatchEvent(
        new CustomEvent("step-change", {
          detail: step,
          bubbles: true,
        }),
      );
    }

    async connectedCallback() {
      this.steps = this.querySelectorAll(".step");
      this.addEventListener("click", (e) => {
        // Handle click on radio or radio label
        if (
          e.target?.name === "sampleType" ||
          e.target?.firstChild?.name === "sampleType"
        ) {
          this.handleSampleTypeChange(e);
        }
      });
      this.addEventListener("step-change", (e) => {
        history.pushState({ step: e.detail }, undefined, `/?step=${e.detail}`);
        this.changeStep(e.detail);
      });

      for (const btn of this.querySelectorAll("button[data-next-step]")) {
        btn.addEventListener("click", function () {
          const validate = this.dataset.checkValidity;
          if (validate && !this.form[validate][0].reportValidity()) {
            return false;
          }
          this.dispatchEvent(
            new CustomEvent("step-change", {
              detail: this.dataset.nextStep,
              bubbles: true,
            }),
          );
        });
      }
    }

    changeStep(step) {
      const changeInternal = () => {
        this.querySelector("bottom-sheet").close();
        this.dataset.currentStep = step;
        if (step !== "step-3") return;
        const li = this.querySelector(".step-2 li:has(input:checked)");
        this.querySelector(".step-3").dataset.restriction =
          li.dataset.restriction;
      };
      if (!document.startViewTransition) changeInternal();
      else document.startViewTransition(() => changeInternal());
    }

    handleSampleTypeChange(e) {
      this.dataset.filter = `step-${e.target.value}`;
      const totalSteps = e.target.dataset.totalSteps;
      this.dataset.totalSteps = totalSteps;
      this.style.setProperty("--total-steps", Number.parseInt(totalSteps, 10));
      this.#renderSteps(totalSteps);
    }

    #renderSteps(totalSteps) {
      const { filter, currentStep } = this.dataset;
      for (const step of this.steps) {
        const isA = (token) => step.classList.contains(token);
        if (isA("step-initial")) continue;
        if (isA("step-final")) {
          const no = step.querySelector("header>small>data");
          no.value = totalSteps;
          no.textContent = totalSteps;
          continue;
        }
        const selectedType = isA(filter);
        for (const el of step.querySelectorAll("fieldset")) {
          el.disabled = !selectedType;
        }
        step.ariaHidden = !selectedType;
      }
      for (const hidden of this.querySelectorAll(
        `.step:is(.${filter}.${currentStep}) input[type=hidden][data-value]`,
      )) {
        hidden.value = hidden.dataset.value;
      }
      for (const hidden of this.querySelectorAll(
        `.step:not(.${filter}) input[type=hidden][data-value]`,
      )) {
        hidden.value = "";
      }
    }
  }
  return ShopSteps;
}

if (globalThis.window?.customElements) {
  defShopSteps(window).register();
}
