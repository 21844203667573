import "./components/shop-steps.js";
import "./components/color-picker.js";
import "./components/custom-validation.js";
import "./components/bottom-sheet.js";
import "./components/order-form.js";
import "./components/order-items.js";
import { formatMoney } from "./lib/format.js";

function registerPacketaWidget(
  orderForm,
  packetaSelectorBtn,
  Packeta,
  packetaApiKey,
  packetaOptions,
) {
  function showSelectedPickupPoint(point) {
    const saveElement = document.querySelector(".packeta-selector-value");
    saveElement.innerText = "";
    if (!point) return;

    console.log("Selected point", point);
    orderForm.delivery.value = "packeta";
    orderForm["delivery-address"].value = point.formatedValue;
    saveElement.insertAdjacentHTML(
      "beforeend",
      `
      <b>${point.place}</b><br>
      <em>${point.openingHours.compactShort}</em><br>
      ${point.name}
      `,
    );
    packetaSelectorBtn.textContent = "Změnit výdejní místo";
  }

  packetaSelectorBtn.addEventListener("click", () =>
    Packeta.Widget.pick(packetaApiKey, showSelectedPickupPoint, packetaOptions),
  );
}

function updateTotalCosts(totalPriceEl, costs) {
  totalPriceEl.value = costs;
  totalPriceEl.innerHTML = formatMoney(costs);
}

function updateCartBadge(cart, itemsCount) {
  cart.dataset.itemsCount = itemsCount;
}

function updateOrderItems(orderItems, items) {
  orderItems.items = items;
}

export async function main({ Packeta, env }) {
  const cart = document.querySelector(".cart");
  const orderForm = document.querySelector("order-form");
  const shopSteps = orderForm.querySelector("shop-steps");
  const colorDetailSheet = shopSteps.querySelector("bottom-sheet");
  const orderItems = shopSteps.querySelector("order-items");
  const delivery = orderForm.querySelector(".delivery");
  const totalPriceEl = orderForm.querySelector("#total-price");
  const sampleType = orderForm.querySelector("#sample-type");

  addEventListener("popstate", (e) =>
    shopSteps.changeStep(e.state?.step ?? "step-1"),
  );

  sampleType.addEventListener("click", (e) => {
    shopSteps.goto("step-2");
    const type = e.target.value;
    if (!new Set(["design", "mosaik"]).has(type)) return;
    for (const img of document.querySelectorAll(`.step-2.step-${type} img`)) {
      img.loading = "eager";
    }
  });

  for (const el of document.querySelectorAll(
    ".color-palettes,.colors--variants",
  )) {
    el.addEventListener("click", (e) => {
      if (e.target.nodeName !== "INPUT") return;
      e.target.scrollIntoView();

      const computedStyle = getComputedStyle(e.target);
      const colorDetailEl = e.target.nextElementSibling.cloneNode(true);
      const { lastStep } = e.target.dataset;

      colorDetailSheet.injectDetail({ computedStyle, colorDetailEl, lastStep });
      colorDetailSheet.open();
    });
  }

  addEventListener("cart:change", (e) => {
    const { items } = e.detail;
    updateTotalCosts(totalPriceEl, orderForm.totalCosts);
    updateCartBadge(cart, items.length);
    updateOrderItems(orderItems, items);
  });

  orderForm.init();

  delivery.addEventListener("change", () => {
    updateTotalCosts(totalPriceEl, orderForm.totalCosts);
  });

  cart.addEventListener("click", () => {
    const itemsCount = cart.dataset.itemsCount;
    if (!itemsCount || itemsCount === "0") return;
    shopSteps.goto(`step-${shopSteps.dataset.totalSteps}`);
  });

  const packetaSelectorBtn = document.querySelector(".packeta-selector-open");
  registerPacketaWidget(
    orderForm,
    packetaSelectorBtn,
    Packeta,
    env["packeta/api-key"],
    env["packeta/options"],
  );
}
